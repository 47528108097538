import "./ContextMenu.module.scss";

import {boundMethod} from "autobind-decorator";
import React from "react";

import sleep from "@/services/sleep";
import {CARD_PADDING} from "@/styles/_variables";
import {IDragBound} from "@toolbox/display-blocks/models";

import Draggable from "@toolbox/display-blocks/Draggable";

interface IContextMenuProps {
    disabled?: boolean;
}

class ContextMenu extends React.PureComponent<IContextMenuProps> {
    private readonly menu = React.createRef<HTMLDivElement>();
    private draggable?: Draggable;
    private mouseDownX: number = -1;
    private mouseDownY: number = -1;

    private get maxLeft() {
        return CARD_PADDING; // not outside of browser
    }

    private get maxRight() {
        return document.body.clientWidth - CARD_PADDING; // not outside of browser
    }

    public componentDidMount() {
        const maxBounds: () => IDragBound = () => ({
            right: this.maxRight,
        });
        this.draggable = new Draggable(this.menu.current!, maxBounds);
    }

    public componentDidUpdate() {
        if (this.props.disabled) {
            this.removeMenu();
        }
    }

    public componentWillUnmount() {
        this.draggable?.dispose();
        this.draggable = undefined;
    }

    @boundMethod
    public onMouseDown(e: React.MouseEvent<HTMLElement, MouseEvent>) {
        const {children, disabled} = this.props;
        if (disabled || !children) {
            return;
        }

        this.mouseDownX = e.clientX;
        this.mouseDownY = e.clientY;
    }

    @boundMethod
    public async onContextMenu(e: React.MouseEvent<HTMLElement, MouseEvent>) {
        const {children, disabled} = this.props;
        const menu = this.menu.current;
        const parent = menu?.parentElement;
        if (disabled || !menu || !parent || !children) {
            return; // abort, if no children or disabled
        }

        const {clientX, clientY} = e;
        if (
            Math.abs(clientX - this.mouseDownX) > 1 ||
            Math.abs(clientY - this.mouseDownY) > 1 // epsilon of 1, if rounding error
        ) {
            return; // abort, if user just is panning
        }

        // only block right click, if not disabled
        e.preventDefault();

        // add menu styles
        const width = Math.min(
            500,
            document.body.clientWidth - 2 * CARD_PADDING, // never bigger than full width
        );
        let left = Math.min(clientX, this.maxRight - width); // not to far right
        if (document.body.clientWidth / 2 < clientX) {
            left = Math.max(this.maxLeft, left - width);
        }

        const box = parent.getBoundingClientRect();
        menu.style.width = width + "px";
        menu.style.top = clientY - box.y + "px";
        menu.style.left = left - box.x + "px";

        // show menu after some time
        await sleep(1);
        menu.classList.add("show");

        // this overwrite can only happen after "show"
        if (window.innerHeight / 2 < clientY) {
            const height = menu.getBoundingClientRect().height;
            menu.style.top = clientY - height - box.y + "px";
        }

        // add listener to close again
        document.addEventListener("click", this.outSideClick, {passive: false});
        document.addEventListener("contextmenu", this.outSideClick, {
            passive: false,
        });

        this.mouseDownX = -1;
        this.mouseDownY = -1;
    }

    public render() {
        return (
            <div
                ref={this.menu}
                id="contextmenu"
                className="dropdown-menu p-0"
                styleName="context-menu"
            >
                {this.props.children}
            </div>
        );
    }

    @boundMethod
    private outSideClick(e: MouseEvent) {
        // this will find menu, if we click in it
        const menu = (e.target as HTMLElement).closest("#contextmenu");
        if (menu) {
            return;
        }

        this.removeMenu();
    }

    private removeMenu() {
        this.menu.current?.classList.remove("show");
        document.removeEventListener("click", this.outSideClick);
        document.removeEventListener("contextmenu", this.outSideClick);
    }
}

export default ContextMenu;
